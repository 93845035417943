#works {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        margin-top: 2%;
    }

}

.work-container {
    display: flex;
    flex-direction: row;
    // justify-content: space-evenly;
    align-items: flex-start;
}

.past-work {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 500px;
    padding: 50px;
    transition: transform .2s;
    color: inherit; /* Set the link color to inherit from the parent */
    text-decoration: none; /* Remove underline */

    img {
        float: left;
        width: 100%;
        height: 25vh;
        object-fit: cover;
        border-radius: 30px;
    }

    h3{
        margin-top: 2vh;
    }

    p{
        text-align: center;
    }
}

.past-work:hover {
    transform: scale(1.25);
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
}

.popup-content {
    // display: flex;
    // flex-direction: row;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
        ". ."; 
    justify-items: center;
    background-color: white;
    padding: 3% 5% 3% 5%;
    border-radius: 30px;
    width: 50%;
    height: 40%;

    img {
        width: 350px;
        height: 270px;
        background-size: cover;
        border-radius: 30px;
    }
}

.close-popup{
    width: 5%;
    height: 5%;
    border-radius: 50%;
    font-family: 'Comfortaa';
    border: none;
    background-color: transparent;
    z-index: 1001;
    // font-size: 50%;
    position: absolute;
    left: 70%;
    top: 31%;
    transition: transform .2s;
}

.close-popup:hover{
    transform: scale(1.1);
}

#CurrentWorks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
}