nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1vw;
    // margin-left: 3vw;
    // margin-right: 2vw;
}

.nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-around;
    li {
        margin-left: 1vw;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 1vw;
        padding-right: 1vw;
        border-radius: 20px;
    }
}

#nav-line {
    min-height: 3px;
    min-width: 50vw;
}

#logo {
    max-width: 3vw;
    margin-right: 3vw;
    // margin-left: 1vw;
}