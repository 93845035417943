.IconText {
    max-width: 30vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5vw;
    margin-top: 2vw;

    // img{
    //     max-width:5vw;
    //     height:auto;
    // }
}

.text-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2vw;
    // max-height: 10vh;
}

.icon-container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
}