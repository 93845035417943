.project-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: 5vw;

    h3 {
        margin-top: 2vh;
    }

    h4 {
        margin-top: 1vh;
    }
}

.persona-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    h4{
        margin-left: 2vw;
    }
}

.centered-image {
    max-width: 55vw;
    max-height: auto;
}

.centered-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.banner-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.side-side-container {
    margin-top: 2vh;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Split the container into two equal halves */
    div {
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
    }
    h4 {
        margin-top: 2vh;
    }

    zoom {
        margin-top:2vh;
    }

    span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 5vw;
    }
}