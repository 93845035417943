h1, h2, h3, h4, p, a {
    font-family: 'Comfortaa', cursive;
    font-weight: bold;
}

h1 {
    font-size: 48px; 
}

h2 {
    font-size: 30px; 
}

h3 {
    font-size: 24px; 
}

h4 {
    font-size: 16px; 
}