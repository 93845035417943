$blue: #4c9eed;
$light-blue: #87cffb;
$grey: #EFEFEF;
$medium-grey: #A7A7A7;
$light-grey: #d9d9d9;
$black: #242424;

body {
    background-color: $grey;
}

.blue-txt {
    color: $blue;
}

.white-txt {
    color: $grey;
}

#nav-line {
    background-color: $medium-grey;
}

.nav-list {
    li {
        a {
            color:black;
            text-decoration: none;
            
        }
        transition: 0.2s;
    }

    li:hover {
        background-color: $light-grey;
    }
}

.black-txt {
    color: black;
}

.grey-txt {
    color: $medium-grey;
}

.blue-line {
    background-color: $blue;
}

.black-background {
    background-color: $black;
}

// .past-work {
//     background-color: $light-blue;
// }